export const formItem = [
	{
		type: 'input',
		label:'费用名称',
		prop:'name',
		placeholder:'请输入'
	},
]
export const tableColumns = [
	{
		title: "费用名称",
		dataIndex: "name",
	},
	{
		title: "计费方式",
		dataIndex: "billingType",
	},
	{
		title: "计量方式",
		dataIndex: "calculateType",
	},
	{
		title: "单价",
		dataIndex: "calculateUnit",
	},
	{
		title: "备注",
		dataIndex: "remarks",
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}