import httpService from "@/request"

// 收费标准列表
export function bindList(params) {
	return httpService({
		url: `/user/chargesBind/list`,
		method: 'post',
		data: params,
	})
}
export function bind(params) {
	return httpService({
		url: `/user/chargesBind/bind`,
		method: 'post',
		data: params,
	})
}
export function unbind(params) {
	return httpService({
		url: `/user/chargesBind/unbind`,
		method: 'post',
		data: params,
	})
}