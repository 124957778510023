import httpService from "@/request"

// 收费标准列表
export function chargesList(params) {
	return httpService({
		url: `/user/charges/list`,
		method: 'get',
		params: params,
	})
}
export function chargesInfo(params) {
	return httpService({
		url: `/user/charges/findById`,
		method: 'get',
		params: params,
	})
}
export function chargesInsert(params) {
	return httpService({
		url: `/user/charges/insert`,
		method: 'post',
		data: params,
	})
}
export function chargesUpdate(params) {
	return httpService({
		url: `/user/charges/update`,
		method: 'post',
		data: params,
	})
}
export function chargesDel(params) {
	return httpService({
		url: `/user/charges/delete`,
		method: 'post',
		data: params,
	})
}