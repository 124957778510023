<template>
  <div>
    <div class="cardTitle">房屋列表</div>
    <div class="content flexbox">
      <div class="tree">
        <a-tree
          v-model="checkedKeys"
          checkable
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="selectedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @check="onCheck"
          @select="onSelect"
          :replaceFields="{
            children: 'childList',
            title: 'name',
            key: 'id',
          }"
        >
        </a-tree>
      </div>
      <a-divider type="vertical" style="height: 100vh"></a-divider>
      <div class="table">
        <div class="cardTitle">已绑定收费标准</div>
        <a-button class="add-btn" style="margin: 10px" @click="addBind"
          >新增绑定</a-button
        >
        <a-table
          :columns="columns"
          :data-source="tableData"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: selectionChoosed,
          }"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
          <span slot="action" slot-scope="scope,row">
            <a-space>
              <a @click="batchUnBind([row.id])">解绑</a>
            </a-space>
          </span>
        </a-table>
        <div class="action">
          <a-dropdown :disabled="!hasSelected">
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="batchUnBind"> 批量解绑 </a-menu-item>
            </a-menu>
            <a-button> 批量操作 <a-icon type="down" /> </a-button>
          </a-dropdown>
          <span style="margin-left: 8px">
            <template v-if="hasSelected">
              {{ `已选择 ${selectedRowKeys.length} 条` }}
            </template>
          </span>
        </div>
      </div>
    </div>
    <a-drawer
      title="绑定房屋收费标准"
      :width="720"
      :visible="drawer.show"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <div class="drawer-content">
        <searchForm
          :formItem="formItem"
          @getSearch="search($event)"
        ></searchForm>
        <a-table
          :columns="tableColumns"
          :data-source="chargeData"
          :pagination="pagination"
          @change="handleTableChange"
          :row-selection="{
            selectedRowKeys: chargesIds,
            onChange: chargeChoosed,
          }"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
        </a-table>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { estate } from "@/api/basic/estate";
import { bindList, bind, unbind } from "@/api/payment/chargeStandardBind";
import { columns } from "./depend/config";
import { formItem, tableColumns, pagination } from "./depend/table";
import { chargesList } from "@/api/payment/chargeStandardManage";
function deleteIds(lists) {
	// 楼栋id和房屋id有重复，导致数形控件选择不可用，把除房屋id外的全改为undefined
	lists.forEach((list) => {
		if (list.childList) {
			list.id = undefined;
			deleteIds(list.childList);
		} else {
			return;
		}
	});
}
export default {
	data() {
		return {
			columns,
			formItem,
			tableColumns,
			pagination,
			drawer: {
				show: false,
			},
			estateIds: [],
			selectedRowKeys: [],
			tableData: [],
			chargeData: [],
			searchForm: {
				name: undefined,
			},
			expandedKeys: [],
			autoExpandParent: true,
			checkedKeys: [],
			selectedKeys: [],
			chargesIds: [],
			treeData: [],
		};
	},
	mounted() {
		this.getEstate();
		this.getData();
	},
	methods: {
		async getEstate() {
			let res = await estate();
			this.treeData = res.data;
			deleteIds(this.treeData);
		},
		async getData() {
			let res = await chargesList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.chargeData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		addBind() {
			if (this.estateIds.length === 0) {
				this.$message.error("请选择房屋");
			} else {
				this.drawer.show = true;
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		onClose() {
			this.drawer.show = false;
			this.selectedRowKeys = []
		},
		async submit() {
			if (this.chargesIds.length === 0) {
				this.$message.error("请选择收费标准");
			} else {
				let res = await bind({
					estateIds: this.estateIds,
					chargesIds: this.chargesIds,
				});
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.onClose();
					this.onCheck(this.estateIds);
				} else {
					this.$message.error(res.msg);
				}
			}
		},
		batchUnBind(ids) {
			this.$confirm({
				title: "是否解绑",
				icon: "rollback",
				onOk: async () => {
					let res = await unbind({
						chargesIds: ids,
						estateIds: this.estateIds,
					});
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.onCheck(this.estateIds);
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		chargeChoosed(data) {
			this.chargesIds = data;
		},
		// 树操作
		onExpand(expandedKeys) {
			this.expandedKeys = expandedKeys;
			this.autoExpandParent = false;
		},
		handleMenuClick(data) {
			if (data.key === "batchUnBind") {
				this.batchUnBind(this.selectedRowKeys);
			}
		},
		onSelect(selectedKeys) {
			this.selectedKeys = selectedKeys;
		},
		onCheck(data) {
			let ids = [];
			for (let id of data) {
				if (typeof id == "number") {
					ids.push(id);
				}
			}
			this.estateIds = ids;
			if (ids.length != 0) {
				bindList({ estateIds: ids }).then((res) => {
					this.tableData = res.data;
				});
			} else {
				this.tableData = []
			}
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.flexbox {
  display: flex;
  justify-content: space-around;
}
.tree {
  padding-left: 30px;
  min-width: 200px;
}
.table {
  width: 80%;
}
</style>
